import React, {createContext, useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import LoginRegistrationService from "../services/LoginRegistrationService";


export const AuthenticationContext = createContext();

export default function AuthenticationContextProvider({children}) {
    

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('token')),
        [token, setToken] = useState(Cookies.get('token') ? Cookies.get('token') : null),
        [name, setName] = useState(Cookies.get('name') ? Cookies.get('name'): null),
        [adminId, setAdminId] = useState(Cookies.get('userId') ? Cookies.get('userId') : null),
        [email, setEmail] = useState(Cookies.get('email') ? Cookies.get('email') : null);
    const [userAccount, setUserAccount] = useState([])
    
    
    useEffect(() => {
        authenticate();
    }, []);

    //Functions
    const authenticate = () => {
        LoginRegistrationService.authenticate().then((response) => {
            if (response.data.success) {
                setUserAccount(response.data.data);
                toggleState(response.data);
            } else {
                logOut();
            }
        }).catch(e => {
            logOut();
        })
    }


    const signIn = async (email, password) => {
        setOpen(true)
        LoginRegistrationService.login({email, password}).then(async (response) => {
            if(response.data.success){
                await Cookies.set('token', response.data.data.token);
                await Cookies.set('userId', response.data.data.userId);
                await Cookies.set('name', response.data.data.name);
                await Cookies.set('email', response.data.data.email);
                setOpen(false);
                setToken(response.data.data.token)
                setUserAccount(response.data.data);
                toggleState(response.data.data)
                toggleIsAuthenticated()
            }else{
                console.log('itey')
                setOpen(false);
                alert('Something went wrong!');
            }
        }).catch(e => {
            console.log(e)
            alert('Something went wrong!')
            setOpen(false);
        });
    }

    function toggleIsAuthenticated() {
        setIsAuthenticated(!isAuthenticated)
    }


    function toggleState(data) {
        setName(data.name)
        setAdminId(data.userId)
        setEmail(data.email)
    }

    async function logOut() {
        Cookies.remove()
        await Cookies.remove('token');
        await Cookies.remove('name');
        await Cookies.remove('userId');
        await Cookies.remove('email');
        setName(null);
        setAdminId(null);
        setToken(null);
        setIsAuthenticated(false);
    }

    return (
        <AuthenticationContext.Provider value={{ isAuthenticated, setIsAuthenticated, token, setToken,name, setName,
            adminId, setAdminId, toggleIsAuthenticated, toggleState, logOut, userAccount, setUserAccount, email, setEmail, signIn, authenticate
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </AuthenticationContext.Provider>
    );
}
